<template>
  <div class="result_check">
    <el-table v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column label="日期" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">开始:{{ scope.row.date }}</span>
          <br />
          <span style="margin-left: 10px">结束:{{ scope.row.endDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实训名称" width="300">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="渠道">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.qudao }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="实训讲师">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.jiangshi }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="时长">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{
              scope.row.dayNum.split("")[scope.row.dayNum.length - 1] == "天"
                ? scope.row.dayNum
                : scope.row.dayNum + "天"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报名人数">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.sx.length }}人
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain>
            <el-link
              type="primary"
              :href="scope.row.file"
              :underline="false"
              size="mini"
              >实训方案</el-link
            >
          </el-button>
          <div style="padding: 5px 0"></div>
          <el-button
            size="mini"
            type="primary"
            plain
            @click="handleCheck(scope.$index, scope.row)"
            >报名详情</el-button
          >
          <div style="padding: 5px 0"></div>
          <el-button
            v-if="scope.row.date > dateStr"
            size="mini"
            type="danger"
            plain
            @click="handleDelete(scope.$index, scope.row)"
            >删除实训</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        small
        layout="prev, pager, next"
        :total="total"
        :page-size="20"
        @current-change="pageAction"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
// 加载loading
import { mapState } from "vuex";
export default {
  name: "SXResultCheck",
  data: function () {
    return {
      tableData: [],
      total: 0,
      date: new Date(),
    };
  },
  computed: {
    ...mapState(["loading"]),
    dateStr() {
      let year = this.date.getFullYear();
      let month =
        this.date.getMonth() + 1 < 10
          ? "0" + (this.date.getMonth() + 1)
          : this.date.getMonth() + 1;
      let date =
        this.date.getDate() < 10
          ? "0" + this.date.getDate()
          : this.date.getDate();
      return `${year}-${month}-${date}`;
    },
  },
  async created() {
    // 获取实训结果
    let res = await this.$datas.sxCheck;
    this.tableData = res.data.data;
    this.total = res.data.count;
  },
  methods: {
    handleCheck(index, row) {
      this.$router.push({ name: "BaoMing", query: { data: row } });
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该实训, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.$datas.meta = { id: row.id };
          let res = await this.$datas.sxDelete;
          //刷新数据源
          if (res.status == 204) {
            this.tableData.splice(index, 1);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async pageAction(num) {
      // 设置meta信息
      this.$datas.meta = { params: { page: num } };
      // 按页获取数据
      let res = await this.$datas.sxCheck;
      this.tableData = res.data.data;
    },
  },
};
</script>
<style lang="less" scoped>
.result_check {
  .check {
    width: 100%;
    padding: 30px 0;

    .el-select {
      width: 80%;
    }
  }
  .page {
    display: flex;
    padding: 20px 0;
    justify-content: flex-end;
  }
  .el-link {
    font-size: 12px;
  }
  .el-link:hover {
    color: white;
  }
}
</style>
